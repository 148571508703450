@import '../colors';

color-picker .color-picker {
  background-color: $color-bg-card;
  border: 1px solid $color-border;

  .hex-text .box div {
    color: $color-text;
  }
}
