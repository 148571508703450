@use '@angular/material' as mat;
@use 'styles/colors';

.mat-mdc-checkbox {
  --mdc-checkbox-state-layer-size: 16px;
  --mdc-checkbox-touch-target-size: 16px;
  --mdc-checkbox-selected-checkmark-color: #{colors.$color-bg-app} !important;

  .mdc-checkbox {
    padding: 0 !important;
    width: 16px;
    height: 16px;
    margin: auto !important;
    margin-right: 8px !important;
    flex: 0 0 16px;
    order: 0;
    vertical-align: middle;

    .mdc-checkbox__native-control {
      top: 0 !important;
      left: 0 !important;

      // to mimic previous constant border-color
      &:enabled:not(:checked):not(:indeterminate):not([data-indeterminate='true'])
        ~ .mdc-checkbox__background {
        border-color: colors.$color-text-secondary !important;
      }
    }

    .mdc-checkbox__background {
      top: 0 !important;
      left: 0 !important;
      width: 16px;
      height: 16px;
    }

    .mat-mdc-checkbox-touch-target {
      transform: initial;
      width: initial;
      height: initial;
    }
  }

  .mat-mdc-checkbox-touch-target,
  .mat-mdc-checkbox-ripple,
  .mdc-checkbox__ripple {
    top: -12px;
    left: -12px;
    right: -12px;
    bottom: -12px;
  }

  .mdc-form-field > label {
    padding: 0;
    line-height: 1.5rem;
  }
}
