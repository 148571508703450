@use 'sass:map';
@use 'styles/colors';

@mixin apply-theme($theme) {
  $is-dark-theme: map.get($theme, is-dark);

  .mat-mdc-slide-toggle {
    .mdc-form-field {
      // bg colors taken from the legacy version of `slide-toggle`
      --mdc-switch-selected-focus-handle-color: #{colors.$color-primary};
      --mdc-switch-selected-focus-track-color: #{colors.$color-primary};
      --mdc-switch-selected-handle-color: #{colors.$color-primary};
      --mdc-switch-selected-hover-handle-color: #{colors.$color-primary};
      --mdc-switch-selected-hover-track-color: #{colors.$color-primary};
      --mdc-switch-selected-pressed-handle-color: #{colors.$color-primary};
      --mdc-switch-selected-pressed-track-color: #{colors.$color-primary};
      --mdc-switch-selected-track-color: #{colors.$color-primary};
      --mdc-switch-unselected-focus-handle-color: #{if($is-dark-theme, #bdbdbd, #fafafa)};
      --mdc-switch-unselected-focus-track-color: #{if($is-dark-theme, #ffffff80, #00000061)};
      --mdc-switch-unselected-handle-color: #{if($is-dark-theme, #bdbdbd, #fafafa)};
      --mdc-switch-unselected-hover-handle-color: #{if($is-dark-theme, #bdbdbd, #fafafa)};
      --mdc-switch-unselected-hover-track-color: #{if($is-dark-theme, #ffffff80, #00000061)};
      --mdc-switch-unselected-pressed-handle-color: #{if($is-dark-theme, #bdbdbd, #fafafa)};
      --mdc-switch-unselected-pressed-track-color: #{if($is-dark-theme, #ffffff80, #00000061)};
      --mdc-switch-unselected-track-color: #{if($is-dark-theme, #ffffff80, #00000061)};
    }
  }
}

.mat-mdc-slide-toggle {
  .mdc-switch__icons {
    display: none;
  }

  .mdc-form-field > label {
    padding-left: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
