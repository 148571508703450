@use 'sass:map';
@use '@angular/material' as mat;
@use 'styles/scrollbar';
@use 'styles/colors';

@mixin mat-dialog-customization($theme) {
  $foreground: map.get($theme, foreground);

  &.theme-default,
  &.theme-dark {
    .mat-mdc-dialog-container {
      --mdc-dialog-supporting-text-color: #{mat.get-color-from-palette($foreground, text)};
    }
  }

  .mat-mdc-dialog-container {
    min-width: 320px !important;

    .mat-mdc-dialog-surface {
      padding: 24px;

      .mat-mdc-dialog-title {
        margin: 0 0 20px;
        padding: 0;
        &::before {
          content: none;
        }
      }
      .mat-mdc-dialog-content {
        padding: 0 24px 12px;
        margin: 0 -24px;
      }
      .mat-mdc-dialog-actions {
        box-sizing: content-box;
        padding: 8px 0;
        margin-bottom: -24px;
      }
    }
  }

  .mat-mdc-dialog-surface {
    @include scrollbar.scrollbar();
  }

  .xs .cdk-overlay-pane {
    max-width: 100vw !important;
  }

  .cdk-overlay-container {
    z-index: 900 !important;

    .mat-mdc-dialog-surface {
      display: flex;
      flex-direction: column;
      opacity: 1;

      & > * {
        display: flex;
        flex-direction: column;
      }

      .mat-mdc-dialog-content {
        @include scrollbar.scrollbar();
      }

      .mat-mdc-dialog-actions {
        justify-content: flex-end;

        .mat-mdc-button + .mat-mdc-button,
        .mat-mdc-button + .mat-mdc-raised-button,
        .mat-mdc-button + share-spinner-button,
        .mat-mdc-raised-button + .mat-mdc-button,
        .mat-mdc-raised-button + .mat-mdc-raised-button,
        .mat-mdc-raised-button + .mat-mdc-outlined-button,
        .mat-mdc-raised-button + share-spinner-button,
        .mat-mdc-outlined-button + .mat-mdc-raised-button,
        share-spinner-button + share-spinner-button {
          margin-left: 20px;

          .xs & {
            margin-left: 10px;
          }
        }
      }
    }
  }

  &.xs .mat-mdc-dialog-surface {
    max-width: none !important;
    max-height: 90vh !important;
  }

  &.gt-sm .mat-mdc-dialog-surface {
    min-width: 400px;
  }
}

@mixin full-screen() {
  & {
    display: block;
    position: relative;
    height: calc(90vh - 48px);

    /* 48 (paddings) + 24 (margins) */
    @media only screen and (max-width: 1279px) {
      width: calc(100vw - 72px);
    }

    @media only screen and (min-width: 1280px) {
      width: 1208px;
    }

    @media only screen and (min-height: 820px) {
      height: 67vh;
      max-height: 800px;
    }
  }

  share-spinner {
    margin: -24px;
  }

  .mat-mdc-dialog-content {
    flex: 1;
    max-height: none;
  }
}
