@use 'sass:map';
@use '@angular/material' as mat;
@use '@material/typography' as mdc-typography;

mdc-typography.$font-family: 'Saira, sans-serif';

// Typography
$typography: map.merge(
  mat.define-typography-config(
    $font-family: mdc-typography.$font-family,
    $headline-1: mat.define-typography-level(7rem, 7rem, 300, $letter-spacing: -0.05em),
    $headline-2: mat.define-typography-level(3.5rem, 7rem, 400, $letter-spacing: -0.02em),
    $headline-3: mat.define-typography-level(2.8126rem, 3rem, 400, $letter-spacing: -0.005em),
    $headline-4: mat.define-typography-level(2.125rem, 2.5rem, 400),
    $headline-5: mat.define-typography-level(1.75rem, 2rem, 500),
    $headline-6: mat.define-typography-level(1.5rem, 2rem, 500),
    $subtitle-1: mat.define-typography-level(1rem, 1.75rem, 400),
    $subtitle-2: mat.define-typography-level(0.9375rem, 1.5rem, 500),
    $body-1: mat.define-typography-level(0.875rem, 1.5rem, 500),
    $body-2: mat.define-typography-level(0.875rem, 1.25rem, 400),
    $caption: mat.define-typography-level(0.75rem, 1.25rem, 400),
    $button: mat.define-typography-level(0.875rem, 0.875rem, 500),
    $overline: mat.define-typography-level(inherit, 1.125, 400)
  ),
  (
    subheading-4: mat.define-typography-level(1.5rem, 1.6, 500),
    subheading-3: mat.define-typography-level(1.25rem, 1.6, 400)
  )
);

// Commented nodes are not migrated
@mixin all-component-typographies($config-or-theme) {
  $config: if(
    mat.private-is-theme-object($config-or-theme),
    mat.get-typography-config($config-or-theme),
    $config-or-theme
  );

  @include mat.badge-typography($config);
  @include mat.typography-hierarchy($config);
  @include mat.button-toggle-typography($config);
  @include mat.divider-typography($config);
  @include mat.datepicker-typography($config);
  @include mat.expansion-typography($config);
  @include mat.grid-list-typography($config);
  @include mat.icon-typography($config);
  @include mat.progress-spinner-typography($config);
  @include mat.sidenav-typography($config);
  @include mat.stepper-typography($config);
  @include mat.sort-typography($config);
  @include mat.toolbar-typography($config);
  @include mat.tree-typography($config);
  @include mat.core-typography($config);
  @include mat.option-typography(
    map.merge(
      $config,
      mat.define-typography-config($body-1: mat.define-typography-level(0.875rem, 1.25, 400))
    )
  );
  @include mat.card-typography($config);
  @include mat.progress-bar-typography($config);
  @include mat.tooltip-typography($config);
  @include mat.form-field-typography(
    map.merge(
      $config,
      mat.define-typography-config(
        $body-1: mat.define-typography-level(0.875rem, 1.125, 400),
        $caption: mat.define-typography-level(0.75rem, 1.125, 400)
      )
    )
  );
  @include mat.input-typography($config);
  @include mat.select-typography(
    map.merge(
      $config,
      mat.define-typography-config(
        $subtitle-1: mat.define-typography-level(0.875rem, 1.125, 400),
        $body-1: mat.define-typography-level(0.875rem, 1.125, 400)
      )
    )
  );
  @include mat.autocomplete-typography(
    map.merge(
      $config,
      mat.define-typography-config(
        $subtitle-1: mat.define-typography-level(0.875rem, 1.125, 400),
        $body-1: mat.define-typography-level(0.875rem, 1.125, 400)
      )
    )
  );
  @include mat.dialog-typography(
    mat.define-typography-config(
      $headline-6: mat.define-typography-level(1.75rem, 2rem, 500),
      $body-1: mat.define-typography-level(0.875rem, 1.25rem, 400)
    )
  );
  @include mat.chips-typography(
    map.merge(
      $config,
      mat.define-typography-config($body-2: mat.define-typography-level(0.875rem, 1.125rem, 500))
    )
  );
  @include mat.slide-toggle-typography($config);
  @include mat.radio-typography($config);
  @include mat.menu-typography(
    map.merge(
      $config,
      mat.define-typography-config($body-1: mat.define-typography-level(0.875rem, 1.15rem, 400))
    )
  );
  @include mat.list-typography(
    map.merge(
      $config,
      mat.define-typography-config(
        $body-1: mat.define-typography-level(1rem, 1.15rem, 400),
        $subtitle-1: mat.define-typography-level(0.875rem, 1rem, 500)
      )
    )
  );
  @include mat.paginator-typography($config);
  @include mat.tabs-typography($config);
  @include mat.checkbox-typography($config);
  @include mat.button-typography($config);
  @include mat.icon-button-typography($config);
  @include mat.fab-typography($config);
  @include mat.table-typography(
    map.merge(
      $config,
      mat.define-typography-config($subtitle-2: mat.define-typography-level(0.75rem, 1.25rem, 700))
    )
  );
}

@include all-component-typographies($typography);

body.mat-typography {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .mat-h1,
  .mat-h2,
  .mat-h3,
  .mat-h4,
  .mat-h5,
  .mat-h6,
  .mat-subtitle-1,
  .mat-subtitle-2 {
    margin-bottom: 0;
  }

  .subheading-3 {
    @include mat.typography-level($typography, subheading-3);
  }

  .subheading-4 {
    @include mat.typography-level($typography, subheading-4);
  }
}
