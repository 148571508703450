@import './colors';

@mixin scrollbar() {
  & {
    scrollbar-width: thin;
    scrollbar-color: $color-gray-out $color-bg-highlight;
  }

  &::-webkit-scrollbar {
    position: absolute;
    bottom: 0;
    right: 0;

    &:vertical {
      width: 8px;
    }

    &:horizontal {
      height: 8px;
    }
  }

  &::-webkit-scrollbar-thumb {
    &:vertical,
    &:horizontal {
      background-color: $color-gray-out;
      border-radius: 10px;
    }
  }

  &::-webkit-scrollbar-track,
  &::-webkit-scrollbar-track-piece {
    background-color: $color-bg-highlight;
  }
}
