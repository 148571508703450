@use 'sass:map';
@use '@angular/material' as mat;
@use 'styles/colors';
@use 'styles/font-sizes';

@mixin apply-theme($theme) {
  $foreground: map.get($theme, foreground);
  $is-dark-theme: map.get($theme, is-dark);

  $label-color: mat.get-color-from-palette(
    $foreground,
    secondary-text,
    if($is-dark-theme, 0.7, 0.6)
  );

  .mat-mdc-form-field.mat-form-field-appearance-outline {
    --mat-mdc-form-field-floating-label-scale: 1;

    @include _outline($theme);

    &.mat-mdc-form-field-label-always-float {
      padding-top: 1.5em;
    }

    .mat-mdc-text-field-wrapper {
      padding-left: 0.75em;
      padding-right: 0.75em;

      input.mat-mdc-form-field-input-control,
      .mat-mdc-select-trigger {
        height: 1.375rem;
      }
    }

    .mat-mdc-form-field-textarea-control {
      padding: 2px 0;
      margin: -2px 0;

      &.cdk-textarea-autosize {
        resize: none;
      }
    }

    .mat-mdc-form-field-subscript-wrapper {
      margin-top: -0.2em;
      padding-bottom: 0.2em;

      .mat-mdc-form-field-error-wrapper,
      .mat-mdc-form-field-hint-wrapper {
        padding-left: 10px;
        padding-right: 5px;
      }

      .mat-mdc-form-field-hint-wrapper {
        color: $label-color;

        .mat-mdc-form-field-hint-spacer {
          display: none;
        }
      }
    }

    .mat-mdc-form-field-prefix,
    .mat-mdc-form-field-suffix,
    .mat-mdc-form-field-icon-prefix,
    .mat-mdc-form-field-icon-suffix {
      place-self: center;

      mat-icon {
        padding: 0;
      }

      .mat-mdc-icon-button {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        font-size: inherit;
        width: 2.5em;
        height: 2.5em;
        padding: 5px;
      }
    }

    .mat-mdc-form-field-infix {
      border-top: 0;
    }

    &.mat-mdc-form-field-label-always-float .mat-mdc-text-field-wrapper {
      top: 0;

      .mat-mdc-floating-label {
        left: 0;
        width: 100%;
        min-width: min-content;
        display: flex;
        transform: translateY(-3.1em) !important;
        pointer-events: auto;
        line-height: 1.125;

        .mat-mdc-form-field-required-marker {
          order: 1;
          margin-right: 0.25em;
          font-size: 1.2em;

          &:after {
            margin-left: 0;
          }
        }

        mat-label {
          display: flex;
          order: 2;

          mat-icon {
            font-size: 1em;
          }
        }
      }
    }

    .mdc-text-field--focused:not(.mdc-text-field--invalid) .mat-mdc-floating-label {
      color: colors.$color-primary;
    }

    &:not(.ng-invalid.ng-touched) .mat-mdc-form-field-required-marker {
      color: colors.$color-red;
    }

    .mat-input-element::placeholder {
      color: colors.$color-text-secondary;
      opacity: 1;
    }
  }

  @include _custom-hint();
  @include _autocomplete();
  @include _select($theme);

  .mat-mdc-form-field:not(.mat-form-field-disabled):not(.ng-invalid) .mat-mdc-floating-label {
    color: colors.$color-text-secondary;
  }

  .mat-mdc-form-field-hint {
    color: $label-color;
  }

  .mat-mdc-form-field-error,
  .mat-mdc-form-field-hint {
    font-size: font-sizes.$size-xs;
  }

  .compact-field,
  .viewer-header-filters share-filterable-select > .mat-mdc-form-field,
  .viewer-header-filters share-server-filterable-select > .mat-mdc-form-field {
    @include compact-field();
  }
}

@mixin _outline($theme) {
  $foreground: map.get($theme, foreground);
  $is-dark-theme: map.get($theme, is-dark);

  $outline-color: mat.get-color-from-palette($foreground, divider, if($is-dark-theme, 0.3, 0.12));
  $outline-color-hover: mat.get-color-from-palette(
    $foreground,
    divider,
    if($is-dark-theme, 1, 0.87)
  );

  &:hover .mat-mdc-text-field-wrapper:not(.mdc-text-field--disabled),
  .mat-mdc-text-field-wrapper.mdc-text-field--focused {
    .mdc-notched-outline__notch {
      padding-top: 0;
      border-top-width: 2px;
    }
  }

  &:hover
    .mat-mdc-text-field-wrapper:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):not(
      .mdc-text-field--invalid
    )
    .mdc-notched-outline
    .mdc-notched-outline {
    &__leading,
    &__notch,
    &__trailing {
      border-width: 2px;
      border-color: $outline-color-hover;
    }

    &__notch {
      border-left-width: 0;
    }
  }

  .mat-mdc-text-field-wrapper {
    .mdc-notched-outline__notch {
      width: auto !important;
      padding-top: 1px;
      border-top-width: 1px;
      border-top-style: solid;
      border-left-width: 0 !important;
    }

    &:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):not(.mdc-text-field--invalid)
      .mdc-notched-outline
      .mdc-notched-outline {
      &__leading,
      &__notch,
      &__trailing {
        border-color: $outline-color;
      }
    }

    &.mdc-text-field--invalid .mdc-notched-outline {
      &__leading,
      &__notch,
      &__trailing {
        border-width: 2px;
      }

      &__notch {
        padding-top: 0;
        border-left-width: 0;
      }
    }
  }
}

@mixin _custom-hint() {
  .custom-hint.mat-mdc-form-field.mat-form-field-appearance-outline {
    position: relative;
    padding-top: 2.6em;
    margin-bottom: 15px;

    .mat-mdc-form-field-subscript-wrapper {
      top: 1.1em;
      position: absolute;
    }

    &.mat-mdc-form-field-label-always-float .mat-mdc-text-field-wrapper .mat-mdc-floating-label {
      transform: translateY(-4.4em) !important;
    }
  }

  &.lt-md {
    .custom-hint.long-hint,
    .long-hint .custom-hint {
      &.mat-mdc-form-field.mat-form-field-appearance-outline {
        padding-top: 3.9em;

        .mat-mdc-form-field-subscript-wrapper {
          top: 1.5em;
        }

        &.mat-mdc-form-field-label-always-float
          .mat-mdc-text-field-wrapper
          .mat-mdc-floating-label {
          transform: translateY(-5.6em) !important;
        }
      }
    }
  }

  &.lt-sm {
    .custom-hint.long-hint,
    .long-hint .custom-hint {
      &.mat-mdc-form-field.mat-form-field-appearance-outline {
        padding-top: 4.4em;

        .mat-mdc-form-field-subscript-wrapper {
          top: 1.1em;
        }

        &.mat-mdc-form-field-label-always-float
          .mat-mdc-text-field-wrapper
          .mat-mdc-floating-label {
          transform: translateY(-6.2em) !important;
        }
      }
    }
  }
}

@mixin _autocomplete() {
  .mdc-menu-surface.mat-mdc-autocomplete-panel {
    padding: 0;

    .mat-mdc-option {
      min-height: 42px;
    }
  }
}

@mixin _select($theme) {
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);

  .mat-mdc-form-field.mat-form-field-appearance-outline {
    .mat-mdc-select {
      .mat-mdc-select-value,
      .mat-mdc-select-placeholder {
        line-height: 1.57;
      }
    }

    &:not(.mat-form-field-disabled) {
      .mat-mdc-select-placeholder {
        color: colors.$color-text-secondary;
        opacity: 1;
      }

      .mat-mdc-select-value {
        color: mat.get-color-from-palette($foreground, text);
        opacity: 1;
      }
    }

    &.mat-form-field-disabled .mat-mdc-select-placeholder {
      color: inherit;
    }
  }

  .mdc-menu-surface.mat-mdc-select-panel {
    padding: 0;
  }

  .mat-mdc-option {
    min-height: 42px;

    &.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled) {
      background: mat.get-color-from-palette($background, hover, 0.12);
    }

    .mdc-list-item__primary-text {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &.mdc-list-item--disabled {
      opacity: 1;

      .mdc-list-item__primary-text {
        color: mat.get-color-from-palette($foreground, hint-text);
      }
    }
  }
}

@mixin compact-field() {
  & {
    &.mat-mdc-form-field.mat-form-field-appearance-outline.mat-mdc-form-field-label-always-float,
    & .mat-mdc-form-field.mat-form-field-appearance-outline.mat-mdc-form-field-label-always-float {
      position: relative;
      padding-top: 0;

      .mat-mdc-form-field-subscript-wrapper {
        position: absolute;
        bottom: 0;
        padding-bottom: 0;

        &:before {
          content: unset;
        }
      }
    }
  }
}
