@use 'sass:map';
@use '@angular/material' as mat;

@mixin apply-theme($theme) {
  $primary: map.get($theme, primary);
  $foreground: map.get($theme, foreground);
  $background: map.get($theme, background);
  $is-dark-theme: map.get($theme, is-dark);

  .mat-calendar-body {
    font-size: 0.875rem;
  }

  .mat-calendar-table-header th {
    font-size: 0.75rem;
  }

  .mat-datepicker-content .mat-calendar {
    height: initial;
  }

  :not(.mat-calendar-body-disabled):hover,
  .cdk-focused .mat-calendar-body,
  .cdk-keyboard-focused .mat-calendar-body,
  .cdk-program-focused .mat-calendar-body {
    &
      > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(
        .mat-calendar-body-in-range
      ) {
      background-color: mat.get-color-from-palette($background, hover);
      color: mat.get-color-from-palette($primary, default-contrast);
    }

    & .mat-calendar-body-in-range {
      background-color: mat.get-color-from-palette($primary, 100);
      color: mat.get-color-from-palette($primary, default-contrast);
      border-style: none;
    }
  }

  .mat-calendar-body-in-range,
  .mat-calendar-body-in-range::before {
    background-color: mat.get-color-from-palette($primary, 100);
  }

  .mat-calendar-body-range-start:not(.mat-calendar-body-range-end),
  .mat-calendar-body-preview-start:not(.mat-calendar-body-preview-end),
  .mat-calendar-body-range-start:not(.mat-calendar-body-range-end)::before,
  .mat-calendar-body-preview-start:not(.mat-calendar-body-preview-end)::before {
    border-radius: 100% 0 0 100%;
  }

  .mat-calendar-body-range-end:not(.mat-calendar-body-range-start),
  .mat-calendar-body-preview-end:not(.mat-calendar-body-preview-start),
  .mat-calendar-body-range-end:not(.mat-calendar-body-range-start)::before,
  .mat-calendar-body-preview-end:not(.mat-calendar-body-preview-start)::before {
    border-radius: 0 100% 100% 0;
  }

  .mat-calendar-body-in-preview,
  .mat-calendar-body-in-preview::before {
    background-color: mat.get-color-from-palette($primary, 100);
  }

  .mat-calendar-body-in-preview .mat-calendar-body-cell-preview {
    border-top: none;
    border-bottom: none;
  }

  .mat-calendar-body > tr .mat-calendar-cell-range ~ .mat-calendar-cell-range {
    border-radius: 0;
  }

  .mat-calendar-body-in-range .mat-calendar-body-cell-range {
    border-top: none;
    border-bottom: none;
  }

  .mat-calendar-cell-over > .mat-calendar-body-cell-content {
    border: mat.get-color-from-palette($primary) 1px solid;
  }

  .mat-calendar-abbr {
    text-decoration: none;
  }

  .mat-calendar-table-header-divider::after {
    left: 0px;
    right: 0px;
  }

  .mat-calendar-content {
    padding: 0;
  }

  @if $is-dark-theme {
    .mat-calendar-body .mat-calendar-body-in-range,
    .mat-calendar-body-in-preview {
      background-color: var(--theme-fg-divider) !important;
    }

    .mat-calendar-body-in-range::before,
    .mat-calendar-body-in-preview::before {
      background: none;
    }
  }
}
