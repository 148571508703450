@use 'sass:map';
@use '@angular/material' as mat;
@use 'styles/colors';
@use 'styles/font-sizes';

@mixin apply-theme($theme) {
  $background: map.get($theme, background);

  .mat-mdc-nav-list {
    padding-bottom: 0;
  }

  .mdc-list.mat-mdc-list-base {
    .mat-mdc-list-item {
      .mat-mdc-list-option-checkbox-before {
        margin-right: 0;

        & + .mdc-list-item__content {
          padding-left: 16px;
        }
      }

      .mdc-list-item__content,
      .mdc-list-item__primary-text {
        overflow: initial;
        white-space: normal;
      }

      .mdc-list-item__primary-text {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      .mat-mdc-subheader {
        color: colors.$color-gray-text;
      }

      &.mat-mdc-list-item-interactive::before {
        background-color: transparent;
      }
    }

    &.mat-mdc-nav-list {
      .mat-mdc-list-item {
        height: 56px;
      }

      a.mat-mdc-list-item {
        cursor: pointer;
      }

      .mat-mdc-list-item-icon {
        margin-right: 0;
      }
    }

    &[dense] {
      .mat-mdc-list-option {
        height: 40px;
        padding: 0;
      }

      .mdc-list-item__primary-text {
        font-size: font-sizes.$size-xs;
      }
    }

    &.mat-mdc-selection-list,
    &.mat-mdc-nav-list {
      .mat-mdc-list-item {
        &:hover {
          background: mat.get-color-from-palette($background, 'hover');
        }
      }
    }
  }

  .mat-mdc-list-option {
    .mdc-list-item__start {
      --mdc-checkbox-selected-checkmark-color: #{colors.$color-bg-app} !important;
    }

    .mdc-checkbox {
      padding: 0;
      margin: 0;

      .mdc-checkbox__background {
        top: 0;
        left: 0;
      }
    }

    &.mdc-list-item--disabled .mdc-list-item__start {
      --mdc-checkbox-selected-icon-color: var(--mdc-checkbox-disabled-selected-icon-color);
      --mdc-checkbox-selected-checkmark-color: inherit !important;
      --mdc-checkbox-unselected-icon-color: var(--mdc-checkbox-disabled-unselected-icon-color);

      cursor: default;
      pointer-events: none;

      .mdc-checkbox .mdc-checkbox__native-control:checked ~ .mdc-checkbox__background {
        border-color: transparent;
      }
    }

    &:not(.mdc-list-item--disabled) .mdc-list-item__start {
      --mdc-checkbox-disabled-selected-icon-color: var(
        --mdc-checkbox-selected-icon-color
      ) !important;
      --mdc-checkbox-disabled-selected-checkmark-color: #{colors.$color-bg-app} !important;

      .mdc-checkbox .mdc-checkbox__native-control:not(:checked) ~ .mdc-checkbox__background {
        border-color: var(--mdc-checkbox-unselected-icon-color) !important;
      }
    }
  }

  .mdc-list-item--with-leading-checkbox .mdc-list-item__start {
    margin-left: 0;
    width: auto;
    height: auto;
  }
}
