@use 'styles/font-sizes';
@import 'scrollbar';

.cm-editor {
  font-size: font-sizes.$size-small;

  .cm-scroller {
    @include scrollbar();
  }

  .cm-gutters {
    background: $color-bg-highlight;
    color: #999;
    border: none;
  }
}

/*** Light theme ***/

.cm-content {
  // pln
  color: #333;
}

.cm-content[data-language='yaml'] {
  // str
  color: #800;

  .cm-variable {
    // kwd
    color: #00f;
  }

  .cm-keyword {
    // lit
    color: #08c;
  }

  .cm-atom {
    // pln
    color: #333;
  }
}

.cm-content .cm-keyword {
  // kwd
  color: #00f;
}

.cm-content .cm-atom,
.cm-content .cm-number {
  // lit
  color: #08c;
}

.cm-content .cm-string {
  // com
  color: #060;
}

.cm-content .cm-comment {
  // com
  color: #060;
  font-style: italic;
}

.cm-content .cm-meta {
  // pun
  color: #660;
}

.cm-content .cm-property {
  // pln
  color: #333;
}
