@use '@angular/material' as mat;
@use 'styles/colors';

.mat-mdc-tab-group {
  .mat-mdc-tab {
    opacity: colors.$opacity-disabled;
    flex-grow: unset !important;
    min-width: 160px;
    padding: 0 24px;
  }

  .mat-mdc-tab-header {
    border-bottom: 1px solid colors.$color-gray-out;
  }

  .mat-mdc-tab:hover .mdc-tab__ripple::before {
    opacity: 0;
  }

  .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab__text-label {
    color: colors.$color-text-secondary;
  }
}
