@use 'sass:map';
@use '@angular/material' as mat;

@mixin apply-theme($theme) {
  $foreground: map.get($theme, foreground);

  .mat-mdc-paginator {
    color: mat.get-color-from-palette($foreground, secondary-text);

    .mat-mdc-form-field {
      padding-top: 0 !important;
    }

    .mat-mdc-paginator-page-size-select {
      width: 64px;
    }

    .mat-mdc-paginator-icon {
      fill: mat.get-color-from-palette($foreground, secondary-text);
    }

    .mat-mdc-select-value {
      color: mat.get-color-from-palette($foreground, text) !important;
    }
  }
}
