/*** aux element for font preloading, 'cousine' font used in pdf reports ***/
body::after {
  display: block;
  width: 0;
  height: 0;
  overflow: hidden;
  content: ' ';
  font-family: cousine, monospace;
}

.html2pdf-custom-overlay {
  display: none;
}

.html2pdf-container-comments {
  opacity: 0.01;
  color: #555;
  width: 490px;
  position: fixed;
  top: 0;
  left: 0;
}

.html2pdf-container-comments {
  font-family: 'Saira', serif;
  font-size: 12px;

  .comment-body {
    margin: 10px 0 20px 0;
  }

  .comment-date {
    float: right;
  }

  p {
    margin-bottom: 10px;
    line-height: 13px;
  }

  h1 {
    font-size: 1.4em;
  }

  h2 {
    font-size: 1.3em;
  }

  h3 {
    font-size: 1.2em;
  }

  h4 {
    font-size: 1.1em;
  }

  code {
    background-color: #ddd;
    font-family: cousine, monospace;
  }

  blockquote {
    border-left: 0.25em solid #dfe2e5;
    margin-left: 0;
    padding: 0 1em;
  }

  ol li {
    /* workaround on html2canvas ol issue: invalid number of items (2 vs 1) + invalid positioning */
    list-style: disc;
  }
}

.html2pdf-container-diff {
  @import './prettyprint';

  opacity: 0.01;
  width: 490px;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  color: #333;
  line-height: 13px;

  * {
    font-family: cousine, monospace;
    font-size: 9px !important;
  }

  .linenums {
    max-width: 100%;
    margin: 0;
    padding-left: 25px;
    color: #aaa;
  }

  .headers,
  .body {
    padding: 8px;
    background-color: #f3f6f7;
    border-radius: 5px;
  }

  .headers {
    margin: 0;

    .header {
      display: flex;
      word-break: break-all;

      .header-key {
        width: 160px;
        min-width: 160px;
      }
    }
  }

  .diff-added {
    background: #c0dac4 !important;
  }

  .diff-removed {
    background: #e9b9bf !important;
    text-decoration: line-through;
  }

  .diff-changed {
    background: #ffd199 !important;
  }

  .diff-added,
  .diff-removed,
  .diff-changed {
    position: static;
  }

  pre {
    margin: 0;
    max-width: 100%;

    &.hex-codes {
      margin: 0 10px;
    }

    &.prettyprint {
      &-hex {
        display: flex;
      }

      white-space: pre-wrap;
      word-break: break-all;

      .prev-aux {
        display: none;
      }
    }

    .removed-anchor::before {
      display: none;
    }

    .removed-anchor {
      position: absolute;
      display: inline-block;
      color: red;
      margin-left: -6px;
      margin-top: -1px;
      font-size: 13px !important;
    }

    &.hex-content .removed-anchor {
      margin-left: -4px;
      margin-top: -0.5px;
    }
  }
}
