/*** Code prettifier styles - shared between code-viewer.component and pdf report ***/
/*
Dark theme from https://raw.githubusercontent.com/chauhan-tarun/google-code-prettify-color-theme
with custom contrast adjustments
*/

.prettyprint {
  .str {
    color: #65c042;
  }

  /* string  - green */
  .kwd {
    color: #f38964;
  }

  /* keyword - dark pink */
  .com {
    color: #aeaeae;
    font-style: italic;
  }

  /* comment - gray */
  .typ {
    color: #89bdff;
  }

  /* type - light blue */
  .lit {
    color: #43b3f9;
  }

  /* literal - blue */
  .pun {
    color: #d9d9d9;
  }

  /* punctuation - white */
  .pln {
    color: #d9d9d9;
  }

  /* plaintext - white */
  .tag {
    color: #89bdff;
  }

  /* html/xml tag    - light blue */
  .atn {
    color: #bdb76b;
  }

  /* html/xml attribute name  - khaki */
  .atv {
    color: #65c042;
  }

  /* html/xml attribute value - green */
  .dec {
    color: #43b3f9;
  }
}
