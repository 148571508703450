.cm-editor .cm-panels {
  background-color: #131719;
  color: #fff;

  .cm-button {
    background: none;
  }

  .cm-textfield {
    background-color: #131719;
  }

  .cm-search [name='close'] {
    color: #fff;
  }
}

.cm-content {
  // pln
  color: #d9d9d9;
}

.cm-cursorLayer .cm-cursor {
  border-left-color: #ffcc00;
}

.cm-content[data-language='yaml'] {
  // str
  color: #65c042;

  .cm-type,
  .cm-variable {
    // kwd
    color: #f38964;
  }

  .cm-keyword {
    // lit
    color: #43b3f9;
  }

  .cm-atom {
    // pln
    color: #d9d9d9;
  }
}

.cm-content .cm-keyword {
  // kwd
  color: #f38964;
}

.cm-content .cm-variable,
.cm-content .cm-self {
  color: inherit;
}

.cm-content .cm-atom,
.cm-content .cm-number {
  // lit
  color: #43b3f9;
}

.cm-content .cm-string {
  // str
  color: #65c042;
}

.cm-content .cm-comment {
  // com
  color: #aeaeae;
  font-style: italic;
}

.cm-content .cm-meta {
  // pun
  color: #d9d9d9;
}

.cm-content .cm-property {
  // pln
  color: #d9d9d9;
}

/*** Dark theme cursor and selection ***/
.cm-selectionLayer {
  .cm-selectionBackground,
  ::selection {
    background: rgba(128, 203, 196, 0.2);
  }
}
