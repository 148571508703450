@use 'colors';

a,
button:not(.mat-calendar-body-cell, .mdc-switch),
.focus-ring,
[role='button'],
[role='tab'],
[role='option'] {
  &:focus-visible {
    outline: 2px solid colors.$color-primary !important;
    outline-offset: -2px;

    &.focus-ring-external {
      outline-offset: 0;
    }

    &:not(.mdc-icon-button, .mat-button-toggle-button, mat-chip) {
      border-radius: 4px;
    }

    &[mat-raised-button][color='primary'],
    &[mat-flat-button][color='primary'],
    &[mat-raised-button][color='accent'],
    &.mat-mdc-button.mat-primary {
      outline-offset: 2px;
    }

    .cdk-keyboard-focused &.mat-sort-header-container,
    &.mat-sort-header-container {
      outline-offset: 1px;
      border-bottom: none;
    }
  }
}

.mat-button-toggle {
  &.mat-button-toggle-checked button:focus-visible {
    outline: 2px solid colors.$color-text-white !important;
    background: colors.$color-primary;
    color: colors.$color-text-white;
    outline-offset: -4px;
  }

  &:not(.mat-button-toggle-checked) button:focus-visible {
    background: colors.$color-primary-focus-bg;

    .theme-dark & {
      color: colors.$color-primary;
    }
  }
}

mat-checkbox,
mat-radio-button {
  input:focus-visible ~ .mat-mdc-focus-indicator {
    outline: 2px solid colors.$color-primary;
    outline-offset: -2px;
    border-radius: 50%;
  }
}

.mat-mdc-slide-toggle {
  button:focus-visible .mat-mdc-focus-indicator {
    outline: 2px solid colors.$color-primary;
    outline-offset: -2px;
    border-radius: 50%;
  }
}

mat-option.mat-active {
  position: relative;
  outline: none !important;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 4px;
    background: colors.$color-primary;
  }
}

.mat-mdc-form-field-invalid:focus-within {
  .mat-mdc-form-field-outline-thick,
  .mat-mdc-select-arrow {
    color: colors.$color-primary !important;
  }
}

.mat-mdc-chip-set
  .mat-mdc-chip.mat-mdc-standard-chip
  .mat-mdc-chip-action.mat-mdc-chip-trailing-icon {
  border-radius: 50%;
}
