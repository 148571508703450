@import 'colors';

.diff-removed {
  text-decoration: line-through;
  color: $color-text;
  user-select: none;

  @include bg-opacity($color-diff-removed, 0.3);
}

.diff-added {
  @include bg-opacity($color-diff-added, 0.3);
}

.diff-changed {
  @include bg-opacity($color-diff-changed, 0.4);
}
