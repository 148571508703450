@use 'sass:map';
@use 'styles/colors';
@use '@material/ripple/ripple-theme';

ripple-theme.$light-ink-opacities: map.merge(
  ripple-theme.$light-ink-opacities,
  (
    hover: 0.04
  )
);

.mat-mdc-menu-panel {
  .mat-mdc-menu-item .mat-icon-no-color {
    --mdc-theme-text-primary-on-background: #{colors.$color-icon};
  }
}
