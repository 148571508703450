.mat-mdc-chip-set {
  .mat-mdc-chip.mat-mdc-standard-chip .mat-mdc-chip-action.mat-mdc-chip-trailing-icon {
    padding-left: 0;
    padding-right: 0;
    margin-left: 8px;
    margin-right: 8px;
  }

  &.mat-mdc-chip-grid {
    .mdc-evolution-chip-set__chips {
      align-items: center;
      margin: -4px;
    }
  }
}
