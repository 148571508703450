@import 'scrollbar';

.tree-container {
  flex: 1;
  padding-bottom: 16px;
  overflow: hidden;

  [mat-icon-button].mat-mdc-icon-button {
    width: 28px;
    height: 28px;
    padding: 3px;
    line-height: 28px;
  }

  .node-name {
    padding-left: 5px;
    white-space: nowrap;
  }

  .node-icon-container {
    position: relative;
    max-height: 28px;
  }

  /*** angular-tree-component overrides ***/

  .node-wrapper {
    align-items: center;
  }

  .node-content-wrapper {
    padding: 0;
    background: inherit !important;
    box-shadow: none;
    display: flex;
    align-items: center;
    height: 28px;
  }

  .tree-children {
    padding-left: 40px;
  }

  tree-node-drop-slot {
    display: none;
  }

  tree-viewport {
    @include scrollbar();
  }
}
