/*** Code prettifier styles - shared between code-viewer.component and pdf report ***/
/* Default (light) theme */

.prettyprint {
  .pln {
    color: #333;
  }

  .str {
    color: #800;
  }

  .kwd {
    color: #00f;
  }

  .com {
    color: #060;
  }

  .typ {
    color: red;
  }

  .lit {
    color: #08c;
  }

  .pun,
  .opn,
  .clo {
    color: #660;
  }

  .tag {
    color: #008;
  }

  .atn {
    color: #606;
  }

  .atv {
    color: #800;
  }

  .dec,
  .var {
    color: #606;
  }

  .fun {
    color: red;
  }
}
