@use 'sass:map';
@use 'colors';
/* based on angular-toastr css https://github.com/Foxandxss/angular-toastr/blob/cb508fe6801d6b288d3afc525bb40fee1b101650/dist/angular-toastr.css */

@mixin apply-theme($theme, $text-color) {
  $is-dark-theme: map.get($theme, is-dark);

  .snack-bar-container {
    --message-text: #{$text-color};
    --container-color: #{if($is-dark-theme, #fafafa, #323232)};
  }
}

/* position */
.toast-center-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-center {
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-left {
  top: 12px;
  left: 12px;
}

.toast-top-right {
  top: 12px;
  right: 12px;
}

.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}

.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}

.toast-container {
  position: fixed;
  z-index: 999999;
}

.toast-container {
  pointer-events: none;
  margin-bottom: 24px;
  z-index: 999999;
  padding: 0 16px 8px;

  .ngx-toastr {
    pointer-events: initial;
    position: relative;
    overflow: hidden;
    padding: 16px;
    border-radius: 4px;
    max-width: 500px;
    margin-bottom: 8px;

    .mat-mdc-button {
      margin: -8px;
    }

    a:hover {
      cursor: pointer;
    }

    .snack-bar {
      display: flex !important; // based on https://github.com/scttcper/ngx-toastr/issues/605#issuecomment-451049321
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .message {
        color: var(--message-text);
      }

      .action .mat-mdc-button {
        color: colors.$color-text-inverted-primary;
      }
    }
  }
}

.toast-container .ngx-toastr:hover {
  opacity: 1;
}

.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-bottom-center .ngx-toastr {
  width: 100%;
}

.toast-container.toast-top-full-width .ngx-toastr,
.toast-container.toast-bottom-full-width .ngx-toastr {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}

.ngx-toastr {
  background-color: var(--container-color);
}

.toast-success {
  background-color: colors.$color-bg-green;
}

.toast-error {
  background-color: colors.$color-bg-red;
}

/* Responsive Design */
@media all and (max-width: 240px) {
  .toast-container .ngx-toastr.div {
    padding: 8px;
    width: 11em;
  }
  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 241px) and (max-width: 480px) {
  .toast-container .ngx-toastr.div {
    padding: 8px;
    width: 18em;
  }
  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 481px) and (max-width: 768px) {
  .toast-container .ngx-toastr.div {
    padding: 15px;
    width: 25em;
  }
}
