// Fixed colors
$color-white: rgb(255, 255, 255);
$color-black: rgb(0, 0, 0);
$opacity-disabled: 0.6;

// Theme-dependent colors
$color-primary: var(--theme-primary-500);
$color-accent: var(--theme-accent-500);
$color-warn: var(--theme-warn-500);

$color-text: var(--theme-fg-text);
$color-text-secondary: var(--theme-fg-text-secondary);
$color-text-primary: var(--theme-fg-primary-text);
$color-text-inverted-primary: var(--theme-fg-inverted-primary-text);
$color-icon: var(--theme-fg-icon);
$color-border: var(--theme-fg-divider);

$color-gray: var(--theme-gray);
$color-gray-text: var(--theme-gray-text);
$color-gray-out: var(--theme-gray-out);
$color-text-white: var(--theme-text-white);

$color-bg-app: var(--theme-bg-app);
$color-bg-card: var(--theme-bg-card);
$color-bg-highlight: var(--theme-bg-highlight);

$color-bg-primary-light-1: var(--theme-bg-primary-light-1);
$color-bg-primary-light-2: var(--theme-bg-primary-light-2);
$color-bg-red: var(--theme-bg-red);
$color-bg-red-light: var(--theme-bg-red-light);
$color-bg-cherry: var(--theme-bg-cherry);
$color-bg-cherry-light: var(--theme-bg-cherry-light);
$color-bg-orange: var(--theme-bg-orange);
$color-bg-orange-light: var(--theme-bg-orange-light);
$color-bg-yellow-light: var(--theme-bg-yellow-light);
$color-bg-blue: var(--theme-bg-blue);
$color-bg-blue-light: var(--theme-bg-blue-light);
$color-bg-green: var(--theme-bg-green);
$color-bg-green-light: var(--theme-bg-green-light);
$color-bg-purple: var(--theme-bg-purple);
$color-bg-purple-light: var(--theme-bg-purple-light);
$color-bg-gray: var(--theme-bg-gray);
$color-bg-gray-out: var(--theme-bg-gray-out);
$color-bg-gray-out-light: var(--theme-bg-gray-out-light);
$color-bg-gray-text-light: var(--theme-gray-text-light);
$color-bg-title: var(--theme-bg-title);
$color-sub-header: var(--theme-ghost-gray1);

$color-green: var(--theme-green);
$color-orange: var(--theme-orange);
$color-orange-light: var(--theme-orange);
$color-yellow: var(--theme-yellow);
$color-red: var(--theme-red);
$color-cherry: var(--theme-cherry);
$color-blue: var(--theme-blue);
$color-purple: var(--theme-purple);

$color-diff-added: $color-green;
$color-diff-removed: $color-red;
$color-diff-changed: $color-orange;

$color-primary-focus-bg: var(--theme-primary-100);

// Status
$color-status-chip-map: (
  modest: $color-bg-red-light,
  active: $color-bg-green-light,
  inactive: $color-bg-orange-light
);

$color-fg-status-chip-map: (
  modest: $color-red,
  active: $color-green,
  inactive: $color-orange
);

// Severity
$color-severity-map: (
  low: $color-bg-blue-light,
  medium: $color-bg-yellow-light,
  high: $color-bg-orange-light,
  critical: $color-bg-red-light
);

$color-severity-fg-map: (
  low: $color-blue,
  medium: $color-yellow,
  high: $color-orange,
  critical: $color-red
);

$color-scan-progress-map: (
  progress_running: $color-orange,
  progress_finished: $color-green
);

// Scan progress
$color-scan-progress-bg-map: (
  progress_running: $color-bg-orange,
  progress_finished: $color-bg-green
);

// Entrypoint connectivity
$color-entrypoint-connectivity-bg-map: (
  ok: $color-bg-green-light,
  unreachable: $color-bg-red-light,
  problem: $color-bg-orange-light,
  skipped: $color-bg-gray-out-light,
  unauthorized: $color-bg-cherry-light
);

$color-entrypoint-connectivity-fg-map: (
  ok: $color-green,
  unreachable: $color-red,
  problem: $color-orange,
  skipped: $color-gray-text,
  unauthorized: $color-cherry
);

// Entrypoint security status
$color-entrypoint-security-status-bg-map: (
  new: $color-bg-purple-light,
  changed: $color-bg-orange-light,
  tested: $color-bg-gray-out-light,
  vulnerable: $color-bg-red-light
);

$color-entrypoint-security-status-fg-map: (
  new: $color-purple,
  changed: $color-orange,
  tested: $color-gray,
  vulnerable: $color-red
);

// Shadows
$shadow_1: rgba(0, 0, 0, 0.2);
$shadow_2: rgba(0, 0, 0, 0.14);
$shadow_3: rgba(0, 0, 0, 0.12);

@mixin bg-opacity($color, $opacity) {
  position: relative;

  body.theme-dark & {
    background:
      linear-gradient(rgba(70, 70, 70, 1-$opacity), rgba(70, 70, 70, 1-$opacity)) no-repeat,
      linear-gradient($color, $color) no-repeat;
  }

  body.theme-default & {
    background:
      linear-gradient(rgba(255, 255, 255, 1-$opacity), rgba(255, 255, 255, 1-$opacity)) no-repeat,
      linear-gradient($color, $color) no-repeat;
  }
}

@mixin mixinColors($colors) {
  @each $element, $color in $colors {
    &-#{$element} {
      color: $color;
    }
  }
}

@mixin mixinBgColors($colors) {
  @each $element, $color in $colors {
    &-#{$element} {
      background-color: $color;
    }
  }
}
