@mixin apply() {
  // https://github.com/angular/components/issues/26056
  .mdc-button.mat-primary {
    --mdc-protected-button-label-text-color: var(--theme-primary-contrast-500);
    --mdc-filled-button-label-text-color: var(--theme-primary-contrast-500);
  }

  .mdc-button.mat-accent {
    --mdc-protected-button-label-text-color: var(--theme-accent-contrast-500);
    --mdc-filled-button-label-text-color: var(--theme-accent-contrast-500);
  }

  .mat-button-toggle,
  .mat-calendar {
    // https://github.com/angular/components/issues/26361
    font-family: Saira, sans-serif;
  }

  // https://github.com/angular/components/issues/26176
  .mat-mdc-button-base .mat-mdc-button-touch-target {
    bottom: 0;
    top: 0;
    height: auto;
    transform: none;
  }

  a,
  button:not(.mat-calendar-body-cell),
  .focus-ring,
  [role='button'],
  [role='tab'],
  [role='option'] {
    &:focus-visible {
      &.mat-icon-button {
        border-radius: 50%;
      }
    }
  }

  // fix until https://github.com/angular/components/issues/25981#issuecomment-1399343294
  .mat-mdc-progress-bar {
    $color-primary-100: rgb(181 218 221);
    $color-accent-100: rgb(181 218 221);
    $color-warn-100: rgb(224 207 179);

    .mdc-linear-progress__buffer-bar {
      background-color: var(--theme-primary-100) !important;
    }

    .mdc-linear-progress__buffer-dots {
      background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='#{$color-primary-100}'/%3E%3C/svg%3E") !important;
    }

    &.mat-accent {
      .mdc-linear-progress__buffer-bar {
        background-color: var(--theme-accent-100) !important;
      }

      .mdc-linear-progress__buffer-dots {
        background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='#{$color-accent-100}'/%3E%3C/svg%3E") !important;
      }
    }

    &.mat-warn {
      .mdc-linear-progress__buffer-bar {
        background-color: var(--theme-warn-100) !important;
      }

      .mdc-linear-progress__buffer-dots {
        background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='#{$color-warn-100}'/%3E%3C/svg%3E") !important;
      }
    }
  }

  // https://github.com/angular/components/issues/26638
  .mat-mdc-menu-panel .mat-mdc-menu-item .mdc-list-item__primary-text {
    display: flex;
    align-items: center;
  }
}
