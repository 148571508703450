@use 'styles/colors';

@mixin mat-table-customization() {
  &.theme-default,
  &.theme-dark {
    .mat-mdc-header-cell {
      color: colors.$color-gray-text;
    }
  }

  .mat-mdc-table {
    .mat-mdc-row {
      height: 48px;
    }

    &:not(.row-pointer) {
      .mdc-data-table__row:not(.mdc-data-table__row--selected):hover {
        background-color: inherit !important;
      }
    }
  }

  .mat-mdc-table {
    .mat-mdc-header-cell,
    .mat-mdc-cell,
    .mat-mdc-footer-cell {
      padding: 0 24px 0 0;

      &:first-of-type {
        padding-left: 24px;
      }
    }
  }

  table.mat-mdc-table {
    border-bottom: 1px solid var(--theme-fg-divider);
  }

  mat-table {
    mat-header-row.mat-mdc-header-row,
    mat-row.mat-mdc-row,
    mat-footer-row.mat-mdc-footer-row {
      border-bottom: 1px solid var(--theme-fg-divider);
    }

    mat-header-cell.mat-mdc-header-cell,
    mat-cell.mat-mdc-cell,
    mat-footer-cell.mat-mdc-footer-cell {
      border-bottom: none;
    }
  }
}

@mixin mat-column-actions() {
  mat-header-cell.mat-mdc-header-cell.mat-column-actions,
  th.mat-mdc-header-cell.mat-column-actions,
  mat-cell.mat-mdc-cell.mat-column-actions,
  td.mat-mdc-cell.mat-column-actions {
    width: 46px;
    min-width: 46px;
    max-width: 46px;
    padding: 0;
    text-align: center;
  }
}
