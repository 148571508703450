@use 'sass:map';
@use '@angular/material' as mat;
@use '@angular/cdk';
@use 'styles/font-sizes';

@use 'material/components';
@use 'material/typography';

@use 'material/button';
@use 'material/calendar';
@use 'material/checkbox';
@use 'material/chips';
@use 'material/dialog';
@use 'material/form-field';
@use 'material/list';
@use 'material/menu';
@use 'material/paginator';
@use 'material/radio';
@use 'material/slide-toggle';
@use 'material/table';
@use 'material/tabs';
@use 'material/migration-fixes';

@use 'toastr';

@import 'normalize.css';
@import 'colors';
@import 'theme';
@import 'scrollbar';
@import 'tree';
@import 'codemirror';
@include cdk.a11y-visually-hidden();

@import '@ali-hm/angular-tree-component/css/angular-tree-component.css';

// Include the base styles for Angular Material core. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Themes

// Override background palette for dark themes.
$custom-dark-theme-background: map.merge(
  mat.$dark-theme-background-palette,
  (
    background: #131719,
    card: #1e2528,
    // #card-bg
    dialog: #1e2528,
    // #card-bg
    raised-button: #292f32,
    // #neutral-button-bg
    selected-disabled-button: #303030,
    tooltip: #131719,
    // #bg
  )
);

/*** Overriding foreground palettes for light and dark themes ***/

$internal-light-theme-text: #131719; // #text

$custom-light-theme-foreground: map.merge(
  mat.$light-theme-foreground-palette,
  (
    base: $internal-light-theme-text,
    secondary-text: #4f6068,
    // #gray-text
    icon: #637883,
    // #gray-icon
    icons: #637883,
    // #gray-icon
    text: $internal-light-theme-text
  )
);

$internal-dark-theme-text: #f2f4f5; // #text

$custom-dark-theme-foreground: map.merge(
  mat.$dark-theme-foreground-palette,
  (
    base: $internal-dark-theme-text,
    disabled-button: rgba($internal-dark-theme-text, 0.3),
    secondary-text: #95a6af,
    // #gray-text
    icon: #7b909a,
    // #gray-icon
    icons: #7b909a,
    // #gray-icon
    text: $internal-dark-theme-text
  )
);

/*** Setup css variables for dynamic color change ***/

@mixin setupCssColorProperties($theme) {
  $foreground: map.get($theme, foreground);
  $background: map.get($theme, background);
  $warn: map.get($theme, warn);
  $is-dark-theme: map.get($theme, is-dark);

  --theme-fg-text: #{mat.get-color-from-palette($foreground, text)};
  --theme-text-white: #f2f4f5;

  --theme-fg-primary-text: #{if($is-dark-theme, #26c6da, #07737d)};
  --theme-fg-inverted-primary-text: #{if($is-dark-theme, #07737d, #26c6da)};
  --theme-fg-text-secondary: #{mat.get-color-from-palette($foreground, secondary-text)};
  --theme-fg-icon: #{mat.get-color-from-palette($foreground, icon)};
  --theme-fg-divider: #{mat.get-color-from-palette($foreground, divider)};

  --theme-bg-app: #{mat.get-color-from-palette($background, background)}; // #bg
  --theme-bg-highlight: #{if($is-dark-theme, #272f33, #f4f6f7)};
  --theme-bg-card: #{mat.get-color-from-palette($background, card)};
  --theme-bg-selected-table-row: #{if($is-dark-theme, #1c2e33, #e6f2f4)};

  --theme-bg-primary-light-1: #{if($is-dark-theme, #243a3f, #e6f3f4)};
  --theme-bg-primary-light-2: #{if($is-dark-theme, #194248, #c4e1e4)};
  --theme-bg-red: #{if($is-dark-theme, #db1e54, #ca1c4d)};
  --theme-bg-red-light: #{if($is-dark-theme, #3b3235, #fcebeb)};
  --theme-bg-cherry: #{if($is-dark-theme, #a41a61, #930f5e)};
  --theme-bg-cherry-light: #{if($is-dark-theme, #372c39, #f4e7ef)};
  --theme-bg-orange: #{if($is-dark-theme, #de8800, #c97b00)};
  --theme-bg-orange-light: #{if($is-dark-theme, #3c382e, #fff9f2)};
  --theme-bg-yellow-light: #{if($is-dark-theme, #3d423a, #fffbeb)};
  --theme-bg-blue: #{if($is-dark-theme, #1b49d4, #1944c5)};
  --theme-bg-blue-light: #{if($is-dark-theme, #263643, #e8f0f9)};
  --theme-bg-green: #{if($is-dark-theme, #12a959, #0f934d)};
  --theme-bg-green-light: #{if($is-dark-theme, #2b3c36, #ecf5ed)};
  --theme-bg-purple: #{if($is-dark-theme, #8b38c4, #8034b5)};
  --theme-bg-purple-light: #{if($is-dark-theme, #332e3f, #f4e9f7)};
  --theme-bg-gray: #{if($is-dark-theme, #3b484e, #4f6068)};
  --theme-bg-gray-out: #{if($is-dark-theme, #272f33, #cad3d7)};
  --theme-bg-gray-out-light: #{if($is-dark-theme, #2c353a, #edeff0)};
  --theme-bg-tooltip: #{mat.get-color-from-palette($background, tooltip, 0.95)};
  --theme-bg-title: #{if($is-dark-theme, #222a2e, #fcfdfd)};

  --theme-blue: #{if($is-dark-theme, #71bbf6, #173eb3)};
  --theme-orange: #{if($is-dark-theme, #ffb74d, #b45b00)};
  --theme-orange-light: #{if($is-dark-theme, #524a38, #f0decc)};
  --theme-yellow: #{if($is-dark-theme, #fff176, #8d6e0b)};
  --theme-red: #{if($is-dark-theme, #ff9696, #b81a46)};
  --theme-cherry: #{if($is-dark-theme, #e37594, #930f5e)};
  --theme-purple: #{if($is-dark-theme, #e196ff, #7530a6)};
  --theme-green: #{if($is-dark-theme, #81c784, #0d7c41)};
  --theme-gray: #{if($is-dark-theme, #b0bcc3, #3b484e)};
  --theme-gray-out: #{if($is-dark-theme, #3b484e, #cad3d7)};
  --theme-gray-text: #{if($is-dark-theme, #95a6af, #4f6068)};
  --theme-gray-text-light: #{if($is-dark-theme, #323b3f, #edeff0)};

  --theme-ghost-gray1: #{if($is-dark-theme, #43494c, #e7ebed)}; // #sub-header
  --theme-ghost-gray2: #{if($is-dark-theme, rgba(236, 235, 235, 0.2), rgba(255, 255, 255, 0.42))};
  --theme-ghost-primary1: #{if($is-dark-theme, #176269, #b2d6da)}; // #animation-green
  --theme-ghost-primary2: #{if($is-dark-theme, rgba(white, 0.5), rgba(0, 169, 184, 0.2))};
}

body {
  $themeDef: (
    color: (
      primary: $primary,
      accent: $accent,
      warn: $warn,
      is-dark: false,
      foreground: $custom-light-theme-foreground,
      background: mat.$light-theme-background-palette
    )
  );

  // see private-create-backwards-compatibility-theme
  $theme: map.merge($themeDef, map.get($themeDef, color));

  @include setupCssColorProperties($theme);
  @include components.apply-themes($theme);
  @include migration-fixes.apply();

  @include calendar.apply-theme($theme);
  @include dialog.mat-dialog-customization($theme);
  @include form-field.apply-theme($theme);
  @include list.apply-theme($theme);
  @include paginator.apply-theme($theme);
  @include slide-toggle.apply-theme($theme);
  @include table.mat-table-customization();
  @include toastr.apply-theme($theme, $internal-dark-theme-text);

  share-code-viewer {
    @import './prettyprint';
  }
}

body.theme-dark {
  $themeDef: (
    color: (
      primary: $primary,
      accent: $accent,
      warn: $warn,
      is-dark: true,
      foreground: $custom-dark-theme-foreground,
      background: $custom-dark-theme-background
    )
  );

  // see private-create-backwards-compatibility-theme
  $theme: map.merge($themeDef, map.get($themeDef, color));

  @include setupCssColorProperties($theme);
  @include components.apply-colors($theme);

  @include button.apply-theme($theme);
  @include calendar.apply-theme($theme);
  @include dialog.mat-dialog-customization($theme);
  @include form-field.apply-theme($theme);
  @include list.apply-theme($theme);
  @include paginator.apply-theme($theme);
  @include slide-toggle.apply-theme($theme);
  @include toastr.apply-theme($theme, $internal-light-theme-text);

  // by default - black for dark theme and with no transparency
  .mat-mdc-list-item-disabled {
    background-color: transparent !important;
    opacity: $opacity-disabled;
    outline: none !important;
  }

  share-code-viewer {
    @import './dark/prettyprint';
  }

  @import './dark/codemirror';
  @import './dark/color-picker';
  @import './dark/overlay';
}

/*** reset ***/

* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

body {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  margin: 0 auto;
  box-sizing: border-box;
}

a {
  color: mat.get-color-from-palette($accent);
  text-decoration: none;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: $opacity-disabled;
  }
}

fieldset {
  margin: 0;
  padding: 0;
  border: none;
}

legend {
  display: block;
  padding: 0;
}

/*** common custom classes ***/

.full-width {
  width: 100%;
}

.icon-okta path {
  stroke: $color-text-secondary;
}

.link {
  font-weight: 400;
  text-decoration: underline;
  cursor: pointer;
  outline-offset: initial;
  padding: 0 3px;
  pointer-events: all;

  &-internal {
    color: $color-text;
  }

  &-external {
    color: $color-text-primary;
  }

  &-one-line {
    white-space: nowrap;
  }
}

button.link-internal {
  border: 0;
  background: none;
  padding: 0;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: $opacity-disabled;
  }

  &:focus-visible {
    outline-offset: 2px !important;
  }
}

.mat-mdc-button:not(:disabled).text-button {
  font-weight: 500;
  color: $color-text-primary;

  &:hover {
    opacity: 1;
  }

  &.button-secondary {
    color: $color-gray-text;
    .mat-icon {
      color: $color-icon;
    }
  }
}

/*** custom material components styles and color classes ***/
body.theme-default,
body.theme-dark {
  .mat-mdc-button-base .mdc-button__label .mat-icon + span,
  .mat-mdc-raised-button .mdc-button__label .mat-icon + span {
    padding-left: 0.75em;
  }

  .mat-mdc-button-base .mat-icon,
  .mdc-icon-button .mat-icon {
    font-size: 24px;
    height: 24px;
    width: 24px;
  }

  .mat-mdc-button-base {
    white-space: nowrap;
  }

  .mat-mdc-button-base.mdc-icon-button:disabled .mat-icon {
    color: $color-gray-out;
  }

  .multi-icons-button {
    display: flex;
    align-items: center;
  }

  .mat-icon[svgIcon],
  .mat-icon[data-mat-icon-type='svg'] {
    svg,
    path {
      fill: currentColor;
    }
  }

  mat-list.mat-mdc-list mat-list-item.mat-mdc-list-item {
    height: inherit;

    .mdc-list-item__content .mdc-list-item__primary-text {
      align-items: baseline;
    }
  }

  .mat-mdc-select-panel {
    @include scrollbar();
  }

  .mat-mdc-tab-group {
    .mat-mdc-tab {
      color: $color-text-secondary;
      transition: font-weight 0s linear 100ms; //fixes broken indicator animation in scan settings (`sm` screen) and scan details

      &.mdc-tab--active {
        color: $color-text-primary;
        opacity: 1;
        font-weight: 600;
      }
    }
  }

  .mat-toolbar {
    padding: 0 24px;
    width: 100%;
    height: 48px;
    min-height: 48px;
    background-color: $color-bg-card;
    box-shadow:
      0 3px 1px -2px $shadow_1,
      0 2px 2px 0 $shadow_2,
      0 1px 0 0 $shadow_3;

    .mat-toolbar-row {
      white-space: pre-wrap;
      padding: 0;
      height: 48px;
      min-height: 48px;
    }

    .mat-mdc-icon-button .mat-icon {
      color: $color-text-primary;
    }

    .mdc-icon-button.mdc-button-disabled .mat-icon {
      color: $color-icon;
    }
  }

  .mat-mdc-tooltip .mdc-tooltip__surface,
  .custom-tooltip {
    background: var(--theme-bg-tooltip);
    white-space: pre-line;
    border: 1px solid var(--theme-fg-divider);
  }

  .mat-mdc-form-field {
    share-info-tooltip {
      margin-left: 4px;
    }
  }

  .mat-mdc-chip-option .mat-mdc-chip-remove:focus-visible {
    border-radius: 50%;
    opacity: 1;
  }

  // filtering textarea from sentry dialog, which is not themed
  consumer-root,
  admin-root,
  mat-dialog-content {
    .mat-mdc-form-field textarea {
      @include scrollbar();

      line-height: 1.5 !important;
      width: 100%;
      max-height: 400px;
      resize: vertical;
      box-sizing: border-box;

      &:not(:disabled) {
        color: $color-text;
      }

      &.mat-mdc-form-field-textarea-control.custom-resizable {
        min-height: 22px !important;
        max-height: 150px !important;
        padding: 0;
        margin: 0;
      }
    }
  }

  .custom-tooltip {
    max-width: 350px;
    margin: 14px;
    padding: 8px;
    color: $color-white;
    border-radius: 4px;
    font-size: font-sizes.$size-xs;
  }

  .btn-urgent {
    &:not(.mat-mdc-icon-button) {
      color: $color-text-white;
      background-color: $color-bg-red;
    }

    &.mat-mdc-icon-button {
      color: $color-red;

      mat-icon {
        color: $color-bg-red;
      }
    }
  }

  .icon-urgent,
  .mat-mdc-menu-item .mat-icon-no-color.icon-urgent {
    color: $color-bg-red;
  }

  .mat-mdc-card-title {
    margin-bottom: 8px;
    font-size: mat.font-size(typography.$typography, 'headline-5');
    line-height: 1.25rem;
  }

  .header-actions {
    display: flex;
    align-items: center;
  }
}

body.theme-dark {
  .custom-tooltip {
    color: $color-text;
  }
}

.custom-label {
  position: static !important;
  display: block !important;
  pointer-events: all !important;
  padding-left: 0.75em;

  body.theme-dark &,
  body.theme-default & {
    color: $color-text-secondary;
  }

  share-info-tooltip {
    margin-left: 4px;
  }
}

.custom-required-marker {
  margin-right: 0.25em;
  font-size: 1.2em;
  color: $color-red;
}

.mat-form-field-appearance-outline.mat-form-field-disabled {
  .mat-form-field-outline-start,
  .mat-form-field-outline-end {
    opacity: 0.5;
  }

  .theme-default & .mat-form-field-outline {
    color: $color-sub-header;
  }
}

body.theme-default,
body.theme-dark {
  .mat-mdc-menu-item[disabled] {
    opacity: 1;

    .mdc-list-item__primary-text {
      color: $color-text-secondary;
    }

    .mat-icon {
      color: $color-gray-out;
    }
  }
}

.cdk-overlay-container .cdk-global-overlay-wrapper {
  pointer-events: auto;
}

.dataset-empty {
  color: $color-icon;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

/*** legacy mat-table styles ***/

.entry {
  mat-table {
    overflow: auto;
    min-height: 300px;

    @include scrollbar();
  }

  mat-row {
    height: 53px;
    min-height: 53px;
  }

  mat-cell,
  mat-footer-cell,
  mat-header-cell {
    text-overflow: ellipsis;
    text-align: left;
    flex: 1;
    display: block;
    align-items: center;
    overflow: hidden;
    white-space: nowrap;
    word-wrap: break-word;
    min-height: unset;
  }
}

/*** new table.mat-mdc-table styles ***/

.mat-mdc-row,
.mat-mdc-header-row {
  &:focus {
    outline: none;
  }
}

.table-container {
  overflow-x: auto;
  height: 100%;
  min-height: 200px;

  @include scrollbar();

  table.mat-mdc-table {
    white-space: nowrap;
    text-align: left;
    min-width: 100%;

    .mat-mdc-cell,
    .mat-footer-cell,
    .mat-mdc-header-cell {
      &.table-column-actions {
        padding: 0 5px 0 10px;
        width: 1px;
      }

      &.table-column-min {
        width: 1px;
      }
    }

    .mat-mdc-cell.table-cell-overflow {
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 50vw;
    }

    // no icon on initial sort opened bug workaround (https://github.com/angular/material2/issues/7838)
    .mat-mdc-header-cell .mat-sort-header-container.mat-sort-header-sorted .mat-sort-header-arrow {
      opacity: 1 !important;
      transform: translateY(0) !important;
    }
  }
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mat-mdc-table {
  .mat-mdc-row.selected {
    background-color: var(--theme-bg-selected-table-row);
  }

  .mat-mdc-header-cell {
    font-weight: bold;
    white-space: nowrap;
  }
}

body .mat-mdc-table.row-pointer .mat-mdc-row {
  cursor: pointer;
  &:hover {
    background-color: $color-bg-highlight;
  }
}

// fix dropdown panel `position` and `width`
.panel-filterable-select,
.panel-server-filterable-select {
  position: absolute !important;
  top: -63px; // height of mat-form-field
  min-width: 350px;

  &.compact {
    top: -50px;
  }
}

/*** MS Edge ***/

.breadcrumb-item::before {
  font-family: 'Segoe UI Symbol', serif; // black-and-white unicode specific chars like '▶' font, important for MS Edge
}

@import 'jspdf';
@import 'diff';

@import 'focus-ring';
